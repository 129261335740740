const wrapperStyle = {
  alignItems: "center",
  background: "url('images/footer-background.jpg')",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  color: "white",
  display: "flex",
  flexDirection: "column",
  padding: "80vh 0 2em 0",
  position: "relative",
};

const iconRow = {
  display: "flex",
  padding: "1em 0",
};

const link = {
  height: "30px",
  width: "30px",
  padding: "1em",
};

function FooterSection() {
  return (
    <div style={wrapperStyle}>
      <div style={iconRow}>
        <a
          style={link}
          href="https://facebook.com/accendstudios/?ref=bookmarks"
        >
          <img width="100%" src="/images/facebook.png" alt="facebook" />
        </a>
        <a style={link} href="https://twitter.com/AccendStudios?lang=en">
          <img width="100%" src="/images/twitter.png" alt="twitter" />
        </a>
      </div>
      <div style={{ padding: "1em 0", textAlign: "center" }}>
        <a
          style={{ textDecoration: "none", fontSize: "1.2em", color: "white" }}
          href="mailto:Info@accendstudios.com"
        >
          Info@accendstudios.com
        </a>
      </div>
      <div style={{ padding: "0 1em", textAlign: "center" }}>
        © 2019 ACCEND STUDIOS - ALL RIGHTS RESERVED. WEBSITE BY ACCEND STUDIOS
      </div>
    </div>
  );
}

export default FooterSection;
