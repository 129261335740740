function PortfolioSecton() {
  const items = [
    { img: "/images/posters/1.jpg", title: "test" },
    { img: "/images/posters/2.jpg", title: "test" },
    { img: "/images/posters/3.jpeg", title: "test" },
    { img: "/images/posters/4.jpg", title: "test" },
    { img: "/images/posters/5.jpg", title: "test" },
    { img: "/images/posters/6.jpg", title: "test" },
    { img: "/images/posters/7.jpg", title: "test" },
    { img: "/images/posters/8.jpg", title: "test" },
    { img: "/images/posters/9.jpg", title: "test" },
    { img: "/images/posters/10.jpg", title: "test" },
    { img: "/images/posters/11.jpg", title: "test" },
    { img: "/images/posters/12.jpg", title: "test" },
  ];
  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "4em 0",
    background: "black",
    color: "white",
  };

  const contentBox = {
    textAlign: "center",
    maxWidth: "800px",
  };

  return (
    <div style={sectionStyle}>
      <div style={contentBox}>
        <h1>Portfolio</h1>
        {items.map(({ img, title }, index) => (
          <img style={{ padding: "2px 0 0 2px" }} src={img} alt={title} />
        ))}
      </div>
    </div>
  );
}

export default PortfolioSecton;
