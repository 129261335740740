import './App.css';
import TopSection from "./components/topSection";
import PortfolioSection from "./components/portfolioSection";
import AboutSection from "./components/aboutSection";
import FooterSection from "./components/footerSection";

function App() {
  return (
    <div className="App">
<TopSection/>
<PortfolioSection/>
<AboutSection/>
<FooterSection/>
    </div>
  );
}

export default App;
