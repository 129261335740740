import { useState } from "react";
import Modal from "react-modal";
import BackgroundVideo from "../backgroundVideo";

function TopSection() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    color: "white",
  };

  const contentBox = {
    textAlign: "center",
    maxWidth: "800px",
  };

  return (
    <div style={sectionStyle}>
      <BackgroundVideo></BackgroundVideo>
      <div style={contentBox}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
            content: {
              border: "none",
              background: "black",
              margin: "0",
              border: "2px solid rgba(225, 225,225, 0.2)",
            },
          }}
        >
          <iframe
            src="https://player.vimeo.com/video/745166742?h=4e3cb1aef8&autoplay=1&title=0&byline=0&portrait=0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal>

        <img style={{ width: "280px" }} alt="logo" src="/images/logo.svg"></img>
        <div
          onClick={openModal}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h1>play </h1>
          <img
            style={{ width: 22, padding: "0 1em" }}
            alt="play"
            src={"/images/play.svg"}
          ></img>
          <h1>reel</h1>
        </div>
      </div>
    </div>
  );
}

export default TopSection;
