function AboutSection() {
  const aboutSectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10em",
    background: "black",
    color: "white",
  };

  const contentBox = {
    textAlign: "center",
    maxWidth: "800px",
  };

  return (
    <div style={aboutSectionStyle}>
      <div style={contentBox}>
        <h1>About Us</h1>
        <p>
          We have a passion to do great work, meet your deadlines and help make
          the process enjoyable. Accend will bring your project to life.
        </p>
        <p>Founded by Joshua Zacharias, with over 25 years of experience. </p>
      </div>
    </div>
  );
}

export default AboutSection;
